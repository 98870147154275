import { render, staticRenderFns } from "./PermissoesDialog.vue?vue&type=template&id=4722cb1c&scoped=true"
import script from "./PermissoesDialog.vue?vue&type=script&lang=js"
export * from "./PermissoesDialog.vue?vue&type=script&lang=js"
import style0 from "./PermissoesDialog.vue?vue&type=style&index=0&id=4722cb1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4722cb1c",
  null
  
)

export default component.exports